import { useState, useEffect } from "react";
import { ReactComponent as Cbean } from "./cbean.svg";
import {ReactComponent as Cbean1} from "./coffee-beans-2.svg"
import {ReactComponent as Espresso} from "./coffee-machine-4.svg"
import {ReactComponent as Espresso1} from "./coffee-machine-3_1.svg"
import {ReactComponent as FrenchPress} from "./coffee.svg"
import {ReactComponent as ItalianCoffee} from "./moka-pot.svg"
import {
  Card,
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Navbar,
 
  MobileNav,
  
  IconButton,
} from "@material-tailwind/react";
import { Link } from 'react-scroll';
import Raiting from './Raiting'
import BuyDialog from "./BuyDialog";

function App() {
  const [openNav, setOpenNav] = useState(false);
  const [openBuyDialog,setOpenBuyDialog]=useState(false)
  const [product,setProduct]=useState({})
  const products=[
    {
      productname:"бленд Етиопия",
      description:"Първокачествена етиопска арабика, с висока киселинност и плодов вкус, допълнена с робуста до 20% за засилване плътността и силата на това страхотно сутрешно кафе за начало на Вашият перфектен ден.",
      acidity:5,
      strength:3,
      suitable:[1,2,3],
      price:28,
      weight:250
    },

    {
      productname:"бленд Бразилия",
      description:"Шоколад и плодове завладяват усещанията Ви отпивайки от това кафе. Ниска киселинност и силен каков послевкус преплетен с леки нотки на стафиди. Отлична комбинация за силно начало на Вашият перфектен ден.",
      acidity:2,
      strength:5,
      suitable:[1,2,3],
      price:28,
      weight:250
    },
    {
      productname:"бленд Гватемала",
      description:"Мек вкус на масло, тъмен шоколад и стафиди със сладък послевкус, ниска киселинност и овладяна сила. Това е Вашият избор за перфектният Ви ден.",
      acidity:2,
      strength:4,
      suitable:[1,2,3],
      price:28,
      
    },
    {
      productname:"бленд Колумбия",
      description:"",
      acidity:1,
      strength:5,
      suitable:[1,2,3],
      price:28,
      
    }
  ]
  const navList=(<ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
  <Typography
    as="li"
    variant="small"
    color="blue-gray"
    className="p-1 font-normal"
    
  >
   <button
      
      variant="text"
      className="flex items-center">
     <Link 
     onClick={()=>{setOpenNav(false)}}
     offset={openNav ? -240:-120}
      
      to="home" 
    
    smooth={true} 
    duration={500}>
     
      Начало
   
  </Link>
  </button>
  </Typography>
  
  <Typography
    as="li"
    variant="small"
    color="blue-gray"
    className="p-1 font-normal"
  >
    <button className="flex items-center">
     <Link 
     
     onClick={()=>{setOpenNav(false)}}
      to="shop" 
    offset={openNav ? -120:0}
    smooth={true} 
    duration={500}>
     
      Нашите кафета
   
  </Link>
  </button>
   
  </Typography>
  <Typography
    as="li"
    variant="small"
    color="blue-gray"
    className="p-1 font-normal"
  >
    <button className="flex items-center">
     <Link 
     
    
      to="delivery" 
      onClick={()=>{setOpenNav(false)}}
      offset={openNav ? -120:0}
    
    smooth={true} 
    duration={500}>
     
      Условия и доставка
   
  </Link>
  </button>
  </Typography>
</ul>

  )
  
  return (
    <div>
    <Navbar fullWidth className="sticky top-0 z-50 mx-auto  py-1 px-4 lg:px-8 lg:py-1">
      <div className="mx-auto flex items-center justify-between text-blue-gray-900">
      
        <Typography as="a"
          href="#"
          variant="lead"
          className="mr-4 cursor-pointer py-1.5 font-bold text-coffee-400"
        >
          
          <span className="inline-block align-middle">DaCoffee</span>
          
        </Typography>
        
        <div className="hidden lg:block">{navList}</div>
        
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <MobileNav open={openNav}>
        {navList}
        
      </MobileNav>
      </Navbar>
    <div>
    <BuyDialog openBuyDialog={openBuyDialog} setOpen={setOpenBuyDialog} {...product}/>
      <div class="min-h-screen bg-coffee-200 section text-coffee-100 bg-coffee1 bg-cover "  id="home">
        <div className="bg-coffee-400 bg-opacity-75 min-h-screen">
        <div className="grid grid-cols-1 md:grid-cols-1">
          <div className="md:mx-48 md:my-24">
          <img 
        className="mx-auto"
        src="/separator.png"
        alt="bla"/>
      <Typography  className=" mt-5 mb-3  text-coffee-100 md:text-2xl sm:text-xl text-justify m-5" >
        Ние сме млада компания от ентусиасти и любители на доброто кафе. 
        Нашата цел е да доставяме блендове на специални кафета, които да направят Вашият ден перфектен. 
        Дали сутрин или в ранния следобед, вкъщи или в офиса, доброто кафе ще Ви тонизира и ще предизвика приятни емоции 
        с усещането за уют и спокойствие. Намерете Вашият любим вкус в някой от нашите блендове и го споделете 
        с любимите си хора, колеги и приятели. 
        </Typography>
        <img 
        className="mx-auto"
        src="/separator.png"
        alt="bla"/>
        </div>
        
      <Typography  className=" mt-5 mb-3  text-coffee-100 md:text-2xl sm:text-xl text-center m-5" >
       Изберете кафето за Вашия перфектен ден сега</Typography>
       <svg class="fill-white animate-bounce mt-10 mx-auto w-100" width="24" height="120" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm5.247 8l-5.247 6.44-5.263-6.44-.737.678 6 7.322 6-7.335-.753-.665z"/></svg>
        </div>
        </div>
      </div>
      <div class="flex justify-center align-center bg-coffee-100 section py-24" id="shop">
      <div class="grid md:grid-cols-2  gap-4 sm:grid-cols-1 m-2 ">
      
        {products.map((el,idx)=>(
          <div class="mt-5 flex flex-col">
          <Card key={idx} className="md:w-96 bg-coffee-100 text-coffee-100 drop-shadow-2xl">
            
              <img
                src="/logo.png"
                alt="img-blur-shadow"
                className="object-scale-down h-36"
              />
           
            <CardBody className="text-center bg-coffee-400 md:h-96">
              <Typography variant="h4" className="mb-2 ">
                {el.productname}
              </Typography>
              <Typography className="mt-5 mb-3 text-cofee-100 h-40" >
                {el.description}
              </Typography>
              <Typography className="mt-5 text-cofee-100 mt-auto mb-auto h-10" >
                <div className="flex flex-row justify-between">
                <div className="flex-1 text-right">Киселинност</div>
                <div className="flex-none">:</div> 
                <div className="flex-1 text-right"><Raiting value={el.acidity} maxvalue={5} height={18} className="fill-coffee-200"/></div>
                </div>
                <div className="flex flex-row justify-between">
                <div className="flex-1 text-right">Сила</div>
                <div className="flex-none">:</div>
                <div className="flex-1 items-left"><Raiting value={el.strength} maxvalue={5} height={18} className="fill-coffee-200"/>
                </div>
                </div>
              </Typography>
              <div className="">
              <div className="flex flex-row justify-between h-10 mt-6">
                <Espresso  height ={26} width={26} className="fill-gray-700"/>
                <Espresso1 height={26} width={26} className="fill-gray-700"/>
                <Cbean1 height={26}  width={26}className="fill-coffee-100"/>
                <FrenchPress height={26}  width={26}className="fill-coffee-100"/>
                <ItalianCoffee height={26}  width={26} className="fill-coffee-100"/>
                </div>
                </div>
              
            </CardBody>
            <CardFooter divider className="flex items-center text-center justify-between py-3 bg-coffee-300 ">
              <Typography variant="small">{parseFloat(el.price).toFixed(2)} EUR</Typography>
              <Typography variant="small"> 
              <Button variant="text" 
              className=' shadow-coffee-400 text-white hover:shadow-coffee-300'
              onClick={()=>{setProduct({...el});setOpenBuyDialog(true)}}
              >
                Поръчай
                </Button></Typography>
              <Typography variant="small" color="white" className=" text-white">
                Тегло: {el.weight}гр.
              </Typography>
            </CardFooter>
          </Card>
          </div>
        ))
        }
       
      
      
      </div>
      </div>
      <div class="min-h-screen bg-coffee-200 section text-coffee-100 bg-coffee1 bg-cover "  id="delivery">
        <div className="bg-coffee-400 bg-opacity-75 min-h-screen">
        <div className="grid grid-cols-1 md:grid-cols-1">
          <div className="md:mx-48 md:my-24">
          <img 
        className="mx-auto"
        src="/separator.png"
        alt="bla"/>
      <Typography  className=" mt-5 mb-3  text-coffee-100 md:text-2xl sm:text-xl text-justify m-5" >
        <ul>
       <li> Всички цени са с включен 20% ДДС.</li>
       <li> Безплатана доставка за поръчки над 100 лева.</li>
        <li>Специални отстъпки за количества над 2 килограма.</li>
        <li>ПОВЕРИТЕЛНОСТ И ЛИЧНИ ДАННИ:
          <p>Вашите лични данни ще бъдат използвани единствено и само за изпращане на Вашата поръчка. Те няма да бъдат предоставяни на трети лица, нито използвани за рекламни или маркетингови цели на трети лица. "DaCoffee" за Вашия перфектен ден си запазва правото да изпраща съобщения за активни или изтичащи промоции, специални цени и промени в условията и политиките на марката. </p>
        </li>
        
        </ul>
        </Typography>
        <img 
        className="mx-auto"
        src="/separator.png"
        alt="bla"/>
        </div>
        
      <Typography  className=" mt-5 mb-3  text-coffee-100 md:text-2xl sm:text-xl text-center m-5" >
       Изберете кафето за Вашия перфектен ден сега</Typography>
       <svg class="fill-white animate-bounce mt-10 mx-auto w-100" width="24" height="120" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm5.247 8l-5.247 6.44-5.263-6.44-.737.678 6 7.322 6-7.335-.753-.665z"/></svg>
        </div>
        </div>
      </div>
    </div>
    <button onclick="buttonHandler()" title="Contact Sale"
        class="fixed z-90 bottom-10 right-8 bg-white md:w-20 md:h-20 w-14 h-14 rounded-full drop-shadow-lg flex justify-center items-center text-white text-5xl hover:bg-white hover:drop-shadow-2xl hover:animate-bounce duration-300">
          <img src="./icons8-coffee-64.png"/>
        </button>
    </div>
  );
}

export default App;
