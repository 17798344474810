import { useForm,Controller } from "react-hook-form";
import { Dialog,Button,DialogBody,DialogFooter,DialogHeader,Radio,Input,Select,Option } from "@material-tailwind/react";

export default function BuyDialog(props){
    const {openBuyDialog,setOpen,productname}=props
    const { register, handleSubmit,control,formState: { errors },reset } = useForm();

    const onSubmit=(data)=>{
        alert(JSON.stringify(data))
        reset()
        setOpen(false)


    }
    
    return(
        <Dialog
        open={
         openBuyDialog
        }
        size={"xxl"}
        
      >
        <DialogHeader>{productname}</DialogHeader>
        <DialogBody divider >
       <form className="grid justify-items-center gap-5" onSubmit={handleSubmit(onSubmit)}>
       <input type="hidden" value={productname} {...register("product")}/>
      <div >
       
      <Controller
        name="qty"
        rules={{ required: true }}
        control={control}
        render={({ field }) => 
        
        <Select {...field} error={errors.qty ? true:false} id="qty" size="md" label="Изберете количество" placeholder="Изберете количество" name="qty"  value="" >
        <Option value="1">1 брой</Option>
        <Option value="2">2 броя</Option>
        <Option value="3">3 броя</Option>
        <Option value="4">4 броя</Option>
        <Option value="5">5 броя</Option>
      </Select>
        }
      />
      
      </div>
         <div>
         <Controller
        name="smilane"
        rules={{ required: true }}
        control={control}
        render={({ field }) => 
          <Select {...field} error={errors.smilane ? true:false}id="smilane" size="md" label="Изберете смилане" placeholder="Изберете смилане" >
        <Option value="zurna">На зърна</Option>
        <Option value="espresso">За еспресо</Option>
        <Option value="italian">За кафеварка</Option>
        <Option value="filter">За филтъркафе</Option>
        <Option value="v60">За v-60</Option>
      </Select>
        }/>
      </div>
      <div><Input label="Код за отстъпка" {...register("coupon")}/></div>
      
      </form>
    
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => {
                reset();setOpen(false)}
            }
            className="mr-1"
          >
            <span>Откажи</span>
          </Button>
          <Button
            type="submit"
            variant="gradient"
            color="green"
            onClick={ handleSubmit(onSubmit)}
          >
            <span>Poruchaj</span>
          </Button>
        </DialogFooter>
      </Dialog>
    )

}